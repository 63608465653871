import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import CardSlim from 'src/components/core/card-slim'
import { Container, Row, Col } from 'react-grid-system'
import Layout from 'src/components/layout/layout'
import styled from 'styled-components'
import shadow from 'src/components/theme/shadows'
import Fade from 'react-reveal/Fade'
import { SRLWrapper } from 'simple-react-lightbox'
import { Helmet } from 'react-helmet'

const StyledCard = styled.div`
  padding: 1rem;
  border-radius: 8px;
  box-shadow: ${shadow(16)};
  background-color: #f3f3f3;
  justify-content: space-between;
  z-index: 10;
  padding-bottom: 8rem;
`

const Breaker = styled.hr`
  background: #cccccc;
  margin: 3rem 2rem;
`

const StyledOverviewContents = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
  }
`

const StyledDetails = styled.div`
  padding: 0 2rem 0 0;
`
const StyledParagraph = styled.p`
  font-family: 'Old Standard TT', serif;
  font-family: 'Cormorant', serif;
  color: #3b3b3b;
  margin-bottom: 0.5em;
`

const StyledName = styled.h3`
  font-family: 'Cinzel', serif;
  color: #3b3b3b;
  margin-bottom: 0.5em;
  font-weight: normal;
  font-size: 2rem;
`

const StyledHr = styled.hr`
  color: #3b3b3b;
  margin-bottom: 1rem;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    * {
      flex-basis: 47%;
      margin: 0.5rem 0;
    }
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const Quote = styled.div`
  font-style: italic;
  font-size: 2rem;
  font-family: 'Cinzel' serif;
  font-weight: normal;
  text-align: center;
`

const QuoteAuthor = styled.div`
  font-style: italic;
  font-size: 1.5rem;
  font-family: 'Cinzel' serif;
  font-weight: normal;
  text-align: right;
`

const CoverWrapper = styled.div`
  width: 100%;
  // height: 50vh;
  margin-bottom: 2rem;
  @media screen and(min-width:  578px) {
    margin-bottom: 4rem;
  }
`

const Hline = styled.div`
  height: 0;
  width: 100%;
  border-bottom: solid 1px black;
  margin: 1rem 0 2rem 0;
  box-shadow: ${shadow(2)};
`

const Description = styled.div`
  p {
    margin: 0.5rem 0;
  }
`

const CoverCredit = styled.h6`
  font-style: italic;
  text-align: right;
  font-weight: normal;
  font-size: 75%;
  margin: 0.5rem;
`

const FWImg = styled(Img)`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`
const MediaLink = styled.a`
  display: block;
`
const MediaLinks = styled.div`
  padding-top: 2rem;
`
export default function Template({ data, pageContext }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { post } = data
  const { thumbs } = data
  const { frontmatter, html } = post
  const {
    projectName,
    projectName2,
    longName,
    longName2,
    projectLocation,
    projectLocation2,
    cover,
    captions,
    credits,
    images,
    media_links,
    links_text,
    cover_credit,
  } = frontmatter
  const thumbnails = thumbs.frontmatter.images
  const options = {
    // Please note that "caption" is singular
    thumbnails: {
      showThumbnails: true,
      thumbnailsOpacity: 0.4,
      thumbnailsSize: ['100px', '80px'],
    },
  }
  const customCaptions = thumbnails.map((t, idx) => {
    let fullAlt
    if (credits && credits[idx].length > 0) {
      fullAlt = (
        <div class="SRLCustomCaption">
          {captions[idx]}{' '}
          <span class="SRLCustomCaption caption-lightbox">{credits[idx]}</span>
        </div>
      )
    } else {
      fullAlt = <div class="SRLCustomCaption">{captions[idx]}</div>
    }
    const obj = { id: idx, caption: fullAlt }
    return obj
  })
  const description = `${projectName} ${projectName2} sculpture by Martin Jennings in ${projectLocation} ${projectLocation2}.`
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Martin Jennings - {projectName} {projectName2}
        </title>
        <meta name="description" content={description} />
      </Helmet>
      <Container>
        <Row>
          <Col>
            <Fade clear>
              <StyledCard>
                {longName && longName2 ? (
                  <StyledName>
                    {longName} {longName2}
                  </StyledName>
                ) : (
                  <StyledName>
                    {projectName} {projectName2}
                  </StyledName>
                )}
                <Hline />
                <CoverWrapper>
                  <FWImg fluid={cover.childImageSharp.fluid} />
                  <CoverCredit>{cover_credit}</CoverCredit>
                </CoverWrapper>
                <Description dangerouslySetInnerHTML={{ __html: html }} />
                <MediaLinks>
                  {media_links &&
                    media_links.map((link, idx) => {
                      return (
                        <MediaLink target="blank" href={link}>
                          {links_text[idx]}
                        </MediaLink>
                      )
                    })}
                </MediaLinks>
                <div className="thumbnails-wrap">
                  <SRLWrapper customCaptions={customCaptions}>
                    {thumbnails.map((t, idx) => {
                      // console.log(customCaptions)
                      // let fullAlt
                      // if( credits && credits[idx].length > 0){
                      //   fullAlt =captions[idx] + "<span class='ciccio'"  + credits[idx] + "</span>"
                      // } else {
                      //   fullAlt = captions[idx]
                      // }
                      return (
                        <div className="prev-wrap">
                          <a
                            href={images[idx].childImageSharp.fixed.src}
                            data-attribute="SRL"
                          >
                            <img
                              className="lightbox-preview"
                              src={t.childImageSharp.fixed.src}
                            />
                          </a>
                        </div>
                      )
                    })}
                  </SRLWrapper>
                </div>
              </StyledCard>
            </Fade>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    thumbs: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        images {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        credits
        projectName
        projectName2
        longName
        longName2
        projectLocation
        projectLocation2
        cover_credit
        captions
        media_links
        links_text
        images {
          childImageSharp {
            fixed(jpegQuality: 90, width: 1024) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cover {
          childImageSharp {
            fixed(jpegQuality: 90, width: 1024) {
              ...GatsbyImageSharpFixed
            }
            fluid(jpegQuality: 90, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
